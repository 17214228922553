/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
body {
  font-size: 12pt;
}
.ui.header .sub.header {
  color: rgba(0,0,0,.8);
}
div {
  scroll-behavior: smooth;
}
div::-webkit-scrollbar {
  display: none;
}
h1.ui.header {
  font-weight: 500;
  font-size: 2.5rem;
  padding-bottom: 1em;
}
h2.ui.header {
  font-size: 1.9rem;
  font-weight: 500;
}
h3.ui.header {
  font-size: 1.5rem;
  font-weight: 500;
}
h4.ui.header {
  font-size: 1.1rem;
  font-weight: 600;
}
h5.ui.header {
  font-size: 1rem;
  font-weight: 500;
}

p {
  font-size: 12pt;
}
b {
  color: #c31;
  font-weight: 600;;
}
cite {
  color: #c31;
  font-weight: 600;;
  font-style: italic;
}
i {
  font-weight: 600;;
}
aside {
  color: #8b230b;
  font-weight: 500;
  font-style: italic;
  font-size: .85em;
}

table tr td{
  font-size: 1.1em;
}


.ui.segment {
  position: relative;
  background: rgba(255, 255, 255, .5);
  box-shadow: none;
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34,36,38,.15);
}
a.ui.header:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
  font-size: .9em;
}
.ui.card>.image, .ui.cards>.card>.image {
  background: #ffffff;
}
.item a {
  color: #555;
  font-size: 11.5pt;
}
.item a:hover {
  color: rgb(46, 100, 199);
}
.ui.list .list>a.item {
  color: #555;
  font-size: 10.5pt;
}
.ui.list .list>a.item:hover {
  color: rgb(46, 100, 199);
}

.animfadeout {
  animation-timing-function: ease-in;
  animation-name: fadeout4;
  animation-duration: .5s;
  animation-fill-mode: forwards;
}
.animfadeout1 {
  animation-delay: 2s;
  animation-timing-function: ease-out;
  animation-name: fadeout;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.animfadeout2 {
  animation-delay: 3s;
  animation-timing-function: ease-out;
  animation-name: fadeout;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.animfadeout2a {
  animation-delay: 3s;
  animation-timing-function: ease-out;
  animation-name: fadeout;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.animfadeout2b {
  animation-delay: 3s;
  animation-timing-function: ease-out;
  animation-name: fadeout;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}
.animfadeout3 {
  animation-delay: 4s;
  animation-timing-function: ease-out;
  animation-name: fadeout;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}
@keyframes fadeout {
  0% {
    visibility: visible;
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: .5;
  }
  90% {
    opacity: .8;
  } 
  100% {
    opacity: 1;
    visibility: visible;
  }
}
.animfadeout4 {
  animation-delay: 7s;
  animation-timing-function: linear;
  animation-name: fadeout4;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.animfadeout5 {
  animation-delay: 8s;
  animation-timing-function: linear;
  animation-name: fadeout4;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}
.animfadeout6 {
  animation-delay: 6s;
  animation-timing-function: linear;
  animation-name: fadeout6;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}
@keyframes fadeout4 {
  0% {
    visibility: visible;
    width: 0;
  }
  10% {
    width: 10%;
  }
  30% {
    width: 20%;
  }
  70% {
    width: 50%;
  }
  90% {
    width: 80%;
  } 
  100% {
    visibility: visible;
    width: 100%;
  }
}
@keyframes fadeout6 {
  0% {
    visibility: visible;
    height: 0;
  }
  10% {
    height: 50px;
  }
  30% {
    height: 100px;
  }
  70% {
    height: 200px;
  }
  90% {
    height: 450px;
  } 
  100% {
    visibility: visible;
    height: 450px;
  }
}


.experiencefadeout1 {
  animation-delay: 1s;
  animation-timing-function: ease-out;
  animation-name: expfadeout1;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.experiencefadeout2 {
  animation-delay: 2s;
  animation-timing-function: ease-out;
  animation-name: expfadeout1;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.experiencefadeout3 {
  animation-delay: 10s;
  animation-timing-function: ease-out;
  animation-name: expfadeout1;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.experiencefadeout4 {
  animation-delay: 12s;
  animation-timing-function: ease-out;
  animation-name: expfadeout1;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
@keyframes expfadeout1 {
  0% {
    visibility: visible;
    opacity: 0;
  }
  30% {
    opacity: .3;
  }
  50% {
    opacity: .5;
  }
  60% {
    opacity: .7;
  }
  90% {
    opacity: .9;
  } 
  100% {
    opacity: 1;
    visibility: visible;
  }
}

.ui.card>.content p, .ui.cards>.card>.content p {
  font-size: 12.5pt;
  color: #333;
}

.richcontent > * {
  font-size: 12pt;
  color: #444;
}


@media only screen and (max-width: 767px) {
  .ui.container {
    width: auto!important;
    margin-left: 0!important;
    margin-right: 0!important;
  }
}